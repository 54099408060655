<template>
  <div class="business-wrapper">
    <header :class="['header']">
      <div class="logo">
        <img src="../../assets/logo.png" />
      </div>
      <div class="nav-cont">
        <div class="nav" @click="jumpTo('Home')">首 页</div>
        <!-- <div class="nav" @click="jumpTo('Business')">主要业务</div> -->
        <div class="nav navActive">关于我们</div>
        <div class="nav" @click="jumpTo('Join')">加入我们</div>
      </div>
    </header>
    <div class="head-title">
      <div class="title">
        关于我们
        <div class="subtitle">About US</div>
      </div>
    </div>
    <div class="brief-wrapper">
      <div class="brief-cont">
        <div class="brief-cont-left">
          <div class="title1">
            华越信息技术有限公司
            <div class="dic"></div>
          </div>
          <div class="p1">
            公司成立于2014年，总部位于古都西安，于2016年建立成都业务部，并在2019年成立了深圳分公司，2021年成都业务部升级为成都分公司。经过7年的时间积淀，公司重点面向企业客户提供研发体系咨询服务，PLM规划与实施服务，BI规划与实施服务，中台规划与实施服务，物联网平台建设，系统运维支持服务，协助企业客户向数字化转型，向数智化发展。
          </div>
          <div class="p1">
            通过与客户的紧密合作，在智能制造、航空航天、政府、互联网行业，结合各行业特点，深挖客户信息化应用，积累了丰富的行业解决方案与实践，能够快速响应客户的变化需求，为客户提供高端，安全，高质量，易扩展的信息化定制解决方案以及相关软件产品、平台及服务。
          </div>
        </div>
        <div class="brief-cont-right">
          <img src="../../assets/hy.png" />
        </div>
      </div>
    </div>
    <div class="history-wrapper">
      <div class="history-cont">
        <div class="title1">发展历程</div>
        <div class="process-wrapper">
          <div class="process-card">
            <div class="info"></div>
            <div class="year">2014年</div>
            <div class="major">
              <div class="dic"></div>
              <div class="line-right"></div>
              <div class="line-bottom"></div>
            </div>
            <div class="year"></div>
            <div class="info">
              <div>公司成立，参与陕重汽选装选配项目规划落地</div>
            </div>
          </div>
          <div class="process-card">
            <div class="info above">
              <div>进入中航工业体系</div>
            </div>
            <div class="year"></div>
            <div class="major">
              <div class="dic"></div>
              <div class="line-left"></div>
              <div class="line-right"></div>
              <div class="line-top"></div>
            </div>
            <div class="year">2015年</div>
            <div class="info"></div>
          </div>
          <div class="process-card">
            <div class="info above"></div>
            <div class="year">2018年</div>
            <div class="major">
              <div class="dic"></div>
              <div class="line-left"></div>
              <div class="line-right"></div>
              <div class="line-bottom"></div>
            </div>
            <div class="year"></div>
            <div class="info">
              <div>深圳分公司成立，同年成为VIVO最佳供应商</div>
              <div>与埃森哲达成战略合作</div>
            </div>
          </div>
          <div class="process-card">
            <div class="info above">
              <div>进入中铁装备领域</div>
            </div>
            <div class="year"></div>
            <div class="major">
              <div class="dic"></div>
              <div class="line-left"></div>
              <div class="line-right"></div>
              <div class="line-top"></div>
            </div>
            <div class="year">2019年</div>
            <div class="info"></div>
          </div>
          <div class="process-card">
            <div class="info above"></div>
            <div class="year">2020年</div>
            <div class="major">
              <div class="dic"></div>
              <div class="line-left"></div>
              <div class="line-right"></div>
              <div class="line-bottom"></div>
            </div>
            <div class="year"></div>
            <div class="info">
              <div>为OPPO提供PLM咨询与落地实施服务</div>
              <div>物联网平台发布</div>
            </div>
          </div>
          <div class="process-card">
            <div class="info above">
              <div>成都分公司成立</div>
              <div>与思迈特达成战略合作</div>
            </div>
            <div class="year"></div>
            <div class="major">
              <div class="dic"></div>
              <div class="line-left"></div>
              <div class="line-right-dashed"></div>
              <div class="line-top"></div>
            </div>
            <div class="year">2021年</div>
            <div class="info"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="staff-style-wrapper">
      <div class="title">员工风采</div>

      <div class="swiper-container">
        <div class="swiper-wrapper">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide>
              <div class="staff-style-cont">
                <div class="card width-1">
                  <img src="../../assets/photo/photo-page1-large.jpg" />
                </div>
                <div class="card width-2">
                  <img src="../../assets/photo/photo-page1-medium.png" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page1-small_1.png" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page1-small_2.jpg" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page1-small_3.png" />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="staff-style-cont">
                <div class="card width-1">
                  <img src="../../assets/photo/photo-page2-large.png" />
                </div>
                <div class="card width-2">
                  <img src="../../assets/photo/photo-page2-medium.png" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page2-small_1.png" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page2-small_2.png" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page2-small_3.png" />
                </div>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="staff-style-cont">
                <div class="card width-1">
                  <img src="../../assets/photo/photo-page3-large.png" />
                </div>
                <div class="card width-2">
                  <img src="../../assets/photo/photo-page3-medium.png" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page3-small_1.png" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page3-small_2.png" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page3-small_3.png" />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="staff-style-cont">
                <div class="card width-1">
                  <img src="../../assets/photo/photo-page4-large.jpg" />
                </div>
                <div class="card width-2">
                  <img src="../../assets/photo/photo-page4-medium.png" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page4-small_1.png" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page4-small_2.png" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page4-small_3.png" />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="staff-style-cont">
                <div class="card width-1">
                  <img src="../../assets/photo/photo-page5-large.jpg" />
                </div>
                <div class="card width-2">
                  <img src="../../assets/photo/photo-page5-medium.jpg" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page5-small_1.jpg" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page5-small_2.jpg" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page5-small_3.jpg" />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="swiper-slide">
              <div class="staff-style-cont">
                <div class="card width-1">
                  <img src="../../assets/photo/photo-page6-large.jpg" />
                </div>
                <div class="card width-2">
                  <img src="../../assets/photo/photo-page6-medium.png" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page6-small_1.jpg" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page6-small_2.jpg" />
                </div>
                <div class="card width-3">
                  <img src="../../assets/photo/photo-page6-small_3.png" />
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <div
            class="swiper-button-prev swiper-button-black"
            style="margin-left: 3%"
          ></div>
          <div
            class="swiper-button-next swiper-button-black"
            style="margin-right: 3%"
          ></div>
        </div>

        <!-- Add Pagination -->

        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-cont">
        <div class="box1">
          <div>
            <svg
              version="1.0"
              id="图层_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
              enable-background="new 0 0 16 16"
              xml:space="preserve"
            >
              <g>
                <path
                  fill="#B8BECC"
                  d="M14,3H2C1.45,3,1,3.45,1,4v8c0,0.55,0.45,1,1,1h12c0.55,0,1-0.45,1-1V4C15,3.45,14.55,3,14,3z M14,12H2V4
                h12V12z"
                />
                <path
                  fill="#B8BECC"
                  d="M6.41,8.5h6c0.28,0,0.5-0.22,0.5-0.5s-0.22-0.5-0.5-0.5h-6c-0.28,0-0.5,0.22-0.5,0.5S6.13,8.5,6.41,8.5z"
                />
                <path
                  fill="#B8BECC"
                  d="M12.48,9.79h-6c-0.28,0-0.5,0.22-0.5,0.5s0.22,0.5,0.5,0.5h6c0.28,0,0.5-0.22,0.5-0.5
                S12.75,9.79,12.48,9.79z"
                />
                <circle fill="#B8BECC" cx="4.33" cy="6.34" r="1" />
              </g>
            </svg>
            西安华越信息技术有限公司
          </div>
          <div>
            <svg
              version="1.0"
              id="图层_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
              enable-background="new 0 0 16 16"
              xml:space="preserve"
            >
              <g>
                <path
                  fill="#B8BECC"
                  d="M8,1.5c-2.99,0-5.43,2.43-5.43,5.43c0,1.97,2.18,4.86,4,6.93C6.93,14.26,7.45,14.5,8,14.5
                  s1.07-0.24,1.43-0.64c1.83-2.07,4-4.96,4-6.93C13.43,3.93,10.99,1.5,8,1.5z M8.68,13.2c-0.35,0.39-1,0.39-1.35,0
                  C4.94,10.49,3.57,8.2,3.57,6.93C3.57,4.49,5.56,2.5,8,2.5s4.43,1.99,4.43,4.43C12.43,8.2,11.06,10.49,8.68,13.2z"
                />
                <path
                  fill="#B8BECC"
                  d="M8,4.5C6.62,4.5,5.5,5.62,5.5,7S6.62,9.5,8,9.5s2.5-1.12,2.5-2.5S9.38,4.5,8,4.5z M8,8.5
                  C7.17,8.5,6.5,7.83,6.5,7S7.17,5.5,8,5.5S9.5,6.17,9.5,7S8.83,8.5,8,8.5z"
                />
              </g>
            </svg>
            西安市高新高新区唐延南路与锦业路交汇处逸翠园i都会4号楼1单元2504
          </div>
          <div>
            <svg
              version="1.0"
              id="图层_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
              enable-background="new 0 0 16 16"
              xml:space="preserve"
            >
              <path
                fill="#B8BECC"
                d="M13,9.56c-1.25-1.19-2.4-1.43-3.5-0.75C9.44,8.85,9.37,8.9,9.29,8.96L9.05,9.14C9.03,9.13,9,9.11,8.98,9.1
              c-0.43-0.33-0.82-0.7-1.16-1.12c-0.3-0.38-0.54-0.8-0.68-1.2L7.13,6.73l0.02-0.01c1.57-1.37,0.86-2.86,0.2-3.85
              C6.77,2,6.07,1.54,5.27,1.5h0c-0.82-0.04-1.41,0.32-2.1,0.9l-0.3,0.25C2.38,3.04,2.08,3.64,2.01,4.38C1.85,6.1,2.9,8.59,4.7,10.72
              c1.79,2.12,4.26,3.78,6.14,3.78c0.57,0,1.09-0.15,1.51-0.5l0.5-0.39c0.6-0.49,0.91-0.91,1.06-1.45C14.1,11.55,14.09,10.59,13,9.56z
              M12.96,11.89c-0.1,0.33-0.3,0.6-0.73,0.95l-0.5,0.39c-1.14,0.93-4.07-0.55-6.27-3.15c-1.61-1.9-2.59-4.16-2.45-5.6
              C3.05,4,3.22,3.65,3.5,3.42l0.31-0.26C4.45,2.62,4.79,2.5,5.15,2.5c0.02,0,0.05,0,0.07,0c0.48,0.02,0.9,0.32,1.3,0.92
              c0.89,1.33,0.67,1.94-0.01,2.53L6.04,6.36l0.04,0.28c0.02,0.11,0.04,0.22,0.08,0.34L6.2,7.09C6.37,7.6,6.68,8.14,7.05,8.61
              c0.39,0.48,0.84,0.91,1.32,1.28c0.1,0.08,0.21,0.15,0.34,0.24l0.37,0.22l0.81-0.59c0.05-0.04,0.1-0.07,0.15-0.1
              c0.51-0.32,1.14-0.46,2.28,0.63C12.9,10.84,13.11,11.36,12.96,11.89z"
              />
            </svg>
            电话：029-81149376
          </div>
          <div>
            <svg
              version="1.0"
              id="图层_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
              enable-background="new 0 0 16 16"
              xml:space="preserve"
            >
              <path
                fill="#B8BECC"
                d="M13.5,3h-11c-0.55,0-1,0.45-1,1v8c0,0.55,0.45,1,1,1h11c0.55,0,1-0.45,1-1V4C14.5,3.45,14.05,3,13.5,3z
              M12.72,4l-4.4,4.07C8.14,8.23,7.87,8.23,7.69,8.09L3.28,4H12.72z M2.5,12V4.64l4.53,4.19c0.28,0.24,0.62,0.35,0.96,0.35
              c0.35,0,0.7-0.12,0.99-0.37l4.51-4.18V12H2.5z"
              />
            </svg>
            hr@xa-huayue.com
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="footer-cont">
        <div class="box2">
          Copyright ©2014 - 2021 西安华越信息技术有限公司
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >陕ICP备14005811号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "./swiper.min.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      swiperOptions: {
        // pagination: {
        //   el: ".swiper-pagination",
        // },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        loop: true,
        //切换速度
        speed: 2000,
        //左右箭头按钮
        navigation: {
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        },
        //不需要自动轮播时
        // autoplay: false,
        // 设置自动轮播的延迟时间
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
    };
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },

  watch: {},

  methods: {
    jumpTo(routerName) {
      this.$router.push(routerName);
    },
  },

  created() {},
  mounted() {
    this.swiper.slideTo(1, 1000, false);
  },
  beforeDestroy() {},
};
</script> 

<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 100%;

  .swiper-wrapper {
    width: 100%;
    height: 100%;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  .swiper-pagination {
    > span.swiper-pagination-bullet-active {
      background: #02a774;
    }
  }
}
.business-wrapper {
  padding-top: 60px;
  width: 100%;
  min-width: 1200px;
  touch-action: none;
  overflow: hidden;
  position: relative;
  .header {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    padding: 0 35px 0 0px;
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px 5px rgba($color: #1f2642, $alpha: 0.1);
    transition: all 0.5s;
    .logo {
      width: 200px;
      > img {
        width: 100%;
      }
    }
    .nav-cont {
      width: 400px;
      height: 60px;
      .nav {
        float: left;
        height: 60px;
        line-height: 60px;
        color: #707070;
        padding: 0 15px;
        cursor: pointer;
        font-size: 18px;
      }
      .nav:hover {
        color: #0b57a3;
        border-bottom: 2px solid #0b57a3;
      }
      .navActive {
        color: #0b57a3;
        border-bottom: 2px solid #0b57a3;
      }
    }
  }
  .head-title {
    height: 250px;
    background: #1c1bc2;
    border-radius: 1px;
    background: url("../../assets/about-banner.png") no-repeat;
    background-size: cover;
    .title {
      font-size: 40px;
      font-weight: 500;
      color: #f6f9ff;
      width: 1200px;
      margin: auto;
      text-align: left;
      padding-top: 90px;
      .subtitle {
        font-size: 24px;
        color: #d3d6dc;
      }
    }
  }
  .brief-wrapper {
    .brief-cont {
      width: 1200px;
      margin: auto;
      text-align: left;
      padding-top: 80px;
      padding-bottom: 30px;
      display: flex;
      .brief-cont-left {
        width: 630px;
        padding-right: 60px;
        .title1 {
          font-size: 32px;
          padding-bottom: 20px;
          font-weight: bold;
          .dic {
            width: 80px;
            height: 6px;
            background: #597ef7;
            border-radius: 3px;
            margin-top: 10px;
          }
        }
        .title2 {
          font-size: 18px;
          padding-bottom: 20px;
          font-weight: bold;
        }
        .p1 {
          font-size: 16px;
          padding-bottom: 40px;
          line-height: 28px;
          color: #3b3d41;
        }
      }
      .brief-cont-right {
        width: 570px;
        height: 347px;
        border-radius: 15px;
        overflow: hidden;
        > img {
          width: 100%;
        }
      }
    }
  }
  .history-wrapper {
    background: #f9fbff;
    .history-cont {
      width: 1200px;
      margin: auto;
      text-align: left;
      padding: 90px 0 120px;
      .title1 {
        font-size: 24px;
        padding-bottom: 20px;
        font-weight: bold;
      }

      .process-wrapper {
        display: flex;
        height: 200px;
        .process-card {
          width: 200px;
          height: 200px;
          padding-top: 100px;
          position: relative;
          .info {
            padding: 0 17px;
            position: absolute;
            > div {
              width: 100%;
              line-height: 20px;
              color: #6c7280;
              font-family: PingFangSC-Regular, PingFang SC;
              position: relative;
            }
            > div::before {
              content: "";
              display: inline-block;
              width: 6px;
              height: 6px;
              background: #6694ff;
              border-radius: 3px;
              left: -10px;
              top: 7px;
              position: absolute;
            }
          }
          .above {
            bottom: 115px;
          }
          .year {
            height: 40px;
            text-align: center;
            line-height: 50px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #282b33;
          }
          .major {
            height: 25px;
            position: relative;
            .dic {
              width: 25px;
              height: 25px;
              background: #dfe8ff;
              border: 6px solid #597ef7;
              border-radius: 50%;
              position: absolute;
              left: calc(50% - 13px);
              z-index: 11;
            }
            .line-left {
              width: 100px;
              height: 1px;
              border-bottom: 2px solid #597ef79d;
              position: absolute;
              left: 0;
              top: 12px;
            }
            .line-right {
              width: 100px;
              height: 1px;
              border-bottom: 2px solid #597ef79d;
              position: absolute;
              right: 0;
              top: 12px;
            }
            .line-right-dashed {
              width: 100px;
              height: 1px;
              border-bottom: 2px dashed #597ef79d;
              position: absolute;
              right: 0;
              top: 12px;
            }
            .line-top {
              height: 44px;
              width: 1px;
              border-left: 1px dashed rgba(39, 106, 173, 0.22);
              position: absolute;
              left: 99px;
              bottom: 27px;
              z-index: 10;
            }
            .line-bottom {
              height: 44px;
              width: 1px;
              border-left: 1px dashed rgba(39, 106, 173, 0.22);
              position: absolute;
              left: 99px;
              top: 12px;
              z-index: 10;
            }
          }
        }
      }
    }
  }
  .staff-style-wrapper {
    background: #fff;
    padding: 30px 0;
    .title {
      width: 1200px;
      margin: auto;
      text-align: left;
      padding: 90px 0 120px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #282b33;
    }
    .staff-style-cont {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .card {
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 40px;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
        background: #444;
        > img {
          width: 100%;
          height: 100%;
          float: left;
        }
      }
      .card::after {
        content: "";
        display: block;
        height: 0;
        clear: both;
      }
      .width-1 {
        width: 680px;
        height: 380px;
      }
      .width-2 {
        width: 480px;
        height: 380px;
      }
      .width-3 {
        width: 373px;
        height: 270px;
      }
    }
  }

  .footer {
    background: #26282e;
    color: #a4a5a8;
    position: relative;
    overflow: hidden;
    height: 77px;
    svg {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-top: -2px;
    }
    .line {
      width: calc(100% - 120px);
      height: 1px;
      background: #494c4f;
      margin: 0 auto;
    }
    .footer-cont {
      width: 1200px;
      margin: auto;
      .box1 {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        line-height: 16px;
      }
      .box2 {
        padding: 15px 0;
        font-size: 12px;
        line-height: 16px;
        a {
          margin-left: 10px;
          color: #a4a5a8;
          text-decoration: none;
        }
      }
      background: #26282e;
    }
  }
}
</style>
.swiper-container {
  width: 200px;
  height: 100px;
  background-color: #f00;
  text-align: center;
}
</style>